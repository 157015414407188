import { Api_Url } from "../constant/Url";

export const GetAllCart = async (data, token) => {
  try {
    const res = await fetch(`${Api_Url}/cart/getallcart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token in the Authorization header
      },
      body: JSON.stringify(data), // Send the data as a JSON string in the request body
    });

    // Parse the response as JSON
    const responseData = await res.json();

    // Check if the request was successful
    if (!res.ok) {
      throw new Error(responseData.message || "Failed to add product to cart");
    }

    // Return the response data (e.g., the updated cart or success message)
    return responseData;
  } catch (error) {
    // Handle errors more explicitly
    console.error("An error occurred while adding to cart:", error.message);
    return { success: false, message: error.message }; // Return error message to handle in the calling function
  }
};

export const UpdateAddToCart = async (data, token) => {
  try {
    const res = await fetch(`${Api_Url}/cart/updatesinglecart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token in the Authorization header
      },
      body: JSON.stringify(data), // Send the data as a JSON string in the request body
    });

    console.log(res, "UpdateAddToCart");

    // Parse the response as JSON
    const responseData = await res.json();

    // Check if the request was successful
    if (!res.ok) {
      throw new Error(responseData.message || "Failed to add product to cart");
    }

    // Return the response data (e.g., the updated cart or success message)
    return responseData;
  } catch (error) {
    // Handle errors more explicitly
    console.error("An error occurred while adding to cart:", error.message);
    return { success: false, message: error.message }; // Return error message to handle in the calling function
  }
};

export const CartProductCheckoutApi = async (data) => {
  try {
    const res = await fetch(`${Api_Url}/order/createorder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // Ensure data is being passed correctly
    });
    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res?.status} ${res?.statusText}`);
    }
    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const RemoveCart = async (data, token) => {
  console.log(data, token, "responseData of remove cart");

  try {
    const res = await fetch(`${Api_Url}/cart/deletesinglecart`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token in the Authorization header
      },
      body: JSON.stringify(data),
    });

    // // Parse the response as JSON
    const responseData = await res.json();

    console.log(responseData, "responseData of remove cart");
  } catch (error) {
    // Log the error for debugging
    console.error(
      "An error occurred while removing the product from the cart:",
      error.message
    );
    return { success: false, message: error.message };
  }
};
