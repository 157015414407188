import React from "react";
import image1 from "../../assets/images/image/off1.jpg";
import image2 from "../../assets/images/image/off2.jpg";
import Slider from "react-slick";

export const OurProductsCategory = () => {
  const TopToBottomSettings = {
    dots: false,
    infinite: true,
    vertical: true,
    rtl: true,
    verticalSwiping: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200, // Large desktops
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // Tablets and small desktops
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile phones
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const BottomToTopSettings = {
    dots: false,
    infinite: true,
    vertical: true,
    rtl: false,
    verticalSwiping: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200, // Large desktops
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // Tablets and small desktops
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile phones
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="container Section_Container desktop-view">
        <div className="row d-flex justify-content-center Section_Container_Box">
          <div className="col-md-12 d-flex justify-content-center homepage_component_heading_txt">
            <h2>Our Products Category</h2>
          </div>
        </div>

        <div className="row px-2">
          <div className="col-md-6">
            <Slider {...TopToBottomSettings}>
              <div className="col-md-12">
                <img
                  src={image1}
                  alt=""
                  className="w-100"
                  style={{ borderRadius: "10px" }}
                ></img>
              </div>
              <div className="col-md-12">
                <img
                  src={image2}
                  alt=""
                  className="w-100"
                  style={{ borderRadius: "10px" }}
                ></img>
              </div>
            </Slider>
          </div>

          <div className="col-md-6">
            <Slider {...BottomToTopSettings}>
              <div className="col-md-12">
                <img
                  src={image1}
                  alt=""
                  className="w-100"
                  style={{ borderRadius: "10px" }}
                ></img>
              </div>
              <div className="col-md-12">
                <img
                  src={image2}
                  alt=""
                  className="w-100"
                  style={{ borderRadius: "10px" }}
                ></img>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};
