import React from "react";
import waterpurifiers from "../../assets/images/shop-by-category/waterpurifiers.png";
import waterdispensers from "../../assets/images/shop-by-category/waterdispensers.png";
import kitchenappliances from "../../assets/images/shop-by-category/kitchenappliances.png";
import vacuumcleaners from "../../assets/images/shop-by-category/vacuumcleaners.png";
import "../../styles/ShopByCategory.css";

export const ShopByCategory = () => {
  return (
    <div className="container Section_Container">
      <div className="row d-flex justify-content-center Section_Container_Box">
        <div className="col-md-12 d-flex justify-content-center homepage_component_heading_txt">
          <h2>Shop By Category</h2>
        </div>
      </div>

      <div className="row shop_by_category_div px-2">
        <div
          className="col-md-6  p-2"
          style={{ paddingRight: "10px", position: "relative" }}
        >
          <a href="/waterpurifiers">
            <img
              src={waterpurifiers}
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </a>
          <div className="shopnow_div">
            <a href="/waterpurifiers">
              <div className="shopnow_button">Shop Now</div>
            </a>
          </div>
        </div>
        <div className="col-md-6 p-2">
          <div className="row">
            <div className="col-md-12 px-2" style={{ position: "relative" }}>
              <a href="/waterdispensers">
                <img
                  src={waterdispensers}
                  alt=""
                  style={{ width: "100%", marginBottom: "10px" }}
                />
              </a>
              <div className="shopnow_div">
                <a href="/waterdispensers">
                  <div className="shopnow_button">Shop Now</div>
                </a>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-6 col-6 px-2"
              style={{ paddingRight: "5px", position: "relative" }}
            >
              <a href="/waterdispensers">
                <img
                  src={kitchenappliances}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </a>

              <div
                style={{
                  position: "absolute",
                  bottom: "5px",
                  right: "20px",
                }}
              >
                <a href="/waterdispensers">
                  <div className="shopnow_button">Shop Now</div>
                </a>
              </div>
            </div>
            <div
              className="col-md-6 col-6 px-2"
              style={{ paddingLeft: "5px", position: "relative" }}
            >
              <a href="/waterdispensers">
                <img
                  src={vacuumcleaners}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </a>
              <div
                style={{
                  position: "absolute",
                  bottom: "5px",
                  right: "20px",
                }}
              >
                <a href="/waterdispensers">
                  <div className="shopnow_button">Shop Now</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
