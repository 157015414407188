import React, { useState } from "react";
import Slider from "react-slick";
import amazon from "../../assets/images/online/amazon.png";
import flipkart from "../../assets/images/online/flipkart.png";
import royalaquafresh from "../../assets/images/brands/royalaquafresh.png";
import aquafresh from "../../assets/images/brands/aquafresh.png";
import keel from "../../assets/images/brands/keel.png";
import grandplus from "../../assets/images/brands/grandplus.png";

export const OtherWayToBuy = () => {
  const [activeSlide, setActiveSlide] = useState("Amazon");

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 3000,
    swipeToSlide: true,

    beforeChange: function (currentSlide, nextSlide) {
      const nextActive = nextSlide === 0 ? "Amazon" : "Flipkart";
      setActiveSlide(nextActive);
    },
  };

  return (
    <div>
      <div className="container Section_Container desktop-view">
        <div className="row d-flex justify-content-center Section_Container_Box">
          <div className="col-md-12 d-flex justify-content-center homepage_component_heading_txt">
            <h2>Other Way To Buy Via Brands</h2>
          </div>
        </div>

        <div className="row ">
          <Slider {...settings} style={{ height: "200px" }}>
            {/* Amazon Slide */}
            <div className="row d-flex align-items-center">
              <div className="col">
                <img
                  src={amazon}
                  alt="Amazon"
                  style={{
                    height: "190px",
                    objectFit: "contain",
                  }}
                />
              </div>

              <div className="col">
                <div className="Section_Card_1">
                  <a
                    href="https://www.amazon.in/s?k=aqua+fresh+water+purifier"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={aquafresh}
                      alt="Aqua Fresh"
                      className="w-100"
                      style={{
                        height: "130px",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
              </div>

              <div className="col">
                <div className="Section_Card_1">
                  <a
                    href="https://www.amazon.in/s?k=aqua+fresh+water+purifier"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={grandplus}
                      alt="grandplus"
                      className="w-100"
                      style={{
                        height: "130px",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
              </div>

              <div className="col">
                <div className="Section_Card_1">
                  <a
                    href="https://www.amazon.in/s?k=aqua+fresh+water+purifier"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={royalaquafresh}
                      alt="royal aqua fresh"
                      className="w-100"
                      style={{
                        height: "130px",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
              </div>

              <div className="col">
                <div className="Section_Card_1">
                  <a
                    href="https://www.amazon.in/s?k=aqua+fresh+water+purifier"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={keel}
                      alt="keel"
                      className="w-100"
                      style={{
                        height: "130px",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>

            {/* Flipkart Slide */}
            <div className="row d-flex align-items-center">
              {/* Flipkart Logo */}
              <div className="col d-flex justify-content-center">
                <img
                  src={flipkart}
                  alt="Flipkart"
                  style={{
                    height: "190px",
                    objectFit: "contain",
                  }}
                />
              </div>

              <div className="col">
                <div className="Section_Card_1">
                  <a
                    href="https://www.flipkart.com/search?q=grandplus+water+purifier"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={aquafresh}
                      alt="Aqua Fresh"
                      className="w-100"
                      style={{
                        height: "130px",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
              </div>

              <div className="col">
                <div className="Section_Card_1">
                  <a
                    href="https://www.flipkart.com/search?q=grandplus+water+purifier"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={grandplus}
                      alt="Aqua Fresh"
                      className="w-100"
                      style={{
                        height: "130px",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
              </div>

              <div className="col">
                <div className="Section_Card_1">
                  <a
                    href="https://www.flipkart.com/search?q=grandplus+water+purifier"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={royalaquafresh}
                      alt="Aqua Fresh"
                      className="w-100"
                      style={{
                        height: "130px",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
              </div>

              <div className="col">
                <div className="Section_Card_1">
                  <a
                    href="https://www.amazon.in/s?k=aqua+fresh+water+purifier"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={keel}
                      alt="Aqua Fresh"
                      className="w-100"
                      style={{
                        height: "130px",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};
