import React, { useEffect, useState } from "react";
import "../../styles/Header.css";
import { Link, useNavigate } from "react-router-dom";
import logoWhite from "../../assets/images/logo/logo-white.png";
import logoColor from "../../assets/images/logo/logo-color.png";
import { FaChevronDown, FaRegUserCircle } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import Cookies from "js-cookie";

import {
  MdLogout,
  MdSearch,
  MdOutlineShoppingCart,
  MdOutlineEdit,
  MdOutlineLocationOn,
  MdOutlineCardGiftcard,
} from "react-icons/md";
import { Api_Url } from "../../constant/Url";
import { SearchProductApi } from "../../api/SearchApi";
import menuData from "../../Data/MenuData";
import { Sidenavbar } from "./Sidenavbar";
import { GetUserProfile } from "../../api/UserApi";
import { getDecryptedCookie } from "../../utils/Utils";
import { FetchAllCategoriesWithSubCategories } from "../../api/CategoryApi";

export const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleScroll = () => {
      if (isDesktop) {
        if (window.scrollY > 80) {
          setIsScrolled(true); // Show Header 2 when scrolled
        } else {
          setIsScrolled(false); // Show Header 1 when at the top
        }
      }
    };

    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);

  // Search Function

  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [query, setQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const placeholders = [
    "Search for Water Purifier",
    "Search for Vacuum Cleaner",
    "Search for Water Dispenser",
  ];
  const typingSpeed = 100; // Typing speed in milliseconds
  const pauseTime = 2000; // Pause between phrases

  const [displayText, setDisplayText] = useState(""); // Current typing text
  const [index, setIndex] = useState(0); // Current placeholder index
  const [charIndex, setCharIndex] = useState(0); // Current character index
  const [isDeleting, setIsDeleting] = useState(false); // Typing or deleting

  useEffect(() => {
    const typeEffect = () => {
      const currentPlaceholder = placeholders[index];

      if (!isDeleting) {
        // Typing effect
        setDisplayText((prev) => currentPlaceholder.slice(0, prev.length + 1));

        if (displayText === currentPlaceholder) {
          // Pause before deleting
          setTimeout(() => setIsDeleting(true), pauseTime);
        }
      } else {
        // Deleting effect
        setDisplayText((prev) => currentPlaceholder.slice(0, prev.length - 1));

        if (displayText === "") {
          // Move to the next placeholder
          setIsDeleting(false);
          setIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
        }
      }
    };

    const timer = setTimeout(
      typeEffect,
      isDeleting ? typingSpeed / 2 : typingSpeed
    );

    return () => clearTimeout(timer);
  }, [displayText, isDeleting, index, placeholders]);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setQuery(value);

    if (value) {
      try {
        const response = await SearchProductApi({ productName: value });
        console.log(response, "response search data");
        setSearchResults(response.data);
        setShowDropdown(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShowDropdown(false);
      setSearchResults([]);
    }
  };

  // Close dropdown when clicking outside

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".search_container")) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    Cookies.remove("UserData");
    window.location.href = "/";
  };

  // Get User Profile

  const userProfile = async () => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
      const token = userData?.token;
      const mobile = userData?.mobile; // Use mobile instead of userId

      if (!token) {
        console.log("Token not found in cookies");
        return;
      }

      // Call GetUserProfile API with mobile and token
      const res = await GetUserProfile(mobile, token);

      if (res?.status === "success") {
        setUserData(res.data); // Store the fetched user profile data in the state
      } else {
        console.error("Failed to fetch user profile", res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    userProfile(); // Fetch the user profile when the component mounts
  }, []);

  // Fetch All Categories

  const [HeadersMenuData, setHeadersMenuData] = useState([]);

  const fetchCategories = async () => {
    try {
      const response = await FetchAllCategoriesWithSubCategories(); // Replace with your API endpoint
      console.log(response, "responseresponseresponse");

      // Map the categories and subcategories to the required format
      const categories = response?.data?.map((category) => ({
        title: category.CategoryName,
        items: category.subcategories.map((sub) => ({
          name: sub.SubCategoryName,
          path: `/${encodeURIComponent(
            category.CategoryName
          )}/${encodeURIComponent(sub.SubCategoryName)}`,
        })),
      }));

      setHeadersMenuData(categories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      {(!isScrolled || !isDesktop) && (
        <div className="header-container">
          <div className="phone_view_icons_div mobile-view py-2">
            <div className="d-flex justify-content-between">
              <img
                src={logoWhite}
                className="img-fluid"
                alt=""
                style={{ width: "200px" }}
              />

              <Link
                to="#sidenavbar"
                className="offcanvas_btn text-white"
                data-bs-toggle="offcanvas"
                aria-controls="offcanvasScrolling"
              >
                <GiHamburgerMenu />
              </Link>
            </div>
            <div className="pt-2">
              <div
                className="search_container w-100"
                style={{ position: "relative" }}
              >
                <input
                  placeholder={displayText}
                  value={query}
                  onChange={handleInputChange}
                  onFocus={() => setShowDropdown(true)}
                  type="search"
                  className="search_field w-100"
                />

                <div className="search_div">
                  <MdSearch />
                </div>

                {showDropdown && (
                  <div className="search_container_dropdown">
                    {searchResults.length > 0 ? (
                      searchResults.map((product, index) => (
                        <div
                          key={index}
                          className="dropdown_item"
                          onClick={() =>
                            navigate(
                              `/waterpurifier/productdetails/${product._id}`
                            )
                          }
                        >
                          <div className="py-2 d-flex align-items-center">
                            <div>
                              <div>
                                <img
                                  src={`${Api_Url}${product?.ProductImage?.[0]}`}
                                  alt={product?.ProductName}
                                  className="img-fluid"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              </div>
                            </div>

                            <div className="px-2">
                              <div style={{ fontWeight: "600" }}>
                                {product.ProductName}
                              </div>
                              <span
                                style={{
                                  fontWeight: "600",
                                  color: "green",
                                }}
                              >
                                {`₹ ${parseInt(
                                  product?.totalPriceWithTax
                                ).toLocaleString()}`}{" "}
                                /-
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="dropdown-item">No products found</div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="container-fluid desktop-view">
            <div className="mobile-view">
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  className="w-100"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  placeholder="Search for products"
                  value={query}
                  onChange={handleInputChange}
                  onFocus={() => setShowDropdown(true)}
                />

                {/* Dropdown menu */}
                {showDropdown && (
                  <div className="search_container_dropdown">
                    {searchResults.length > 0 ? (
                      searchResults.map((product, index) => (
                        <div
                          key={index}
                          className="dropdown_item"
                          onClick={() =>
                            navigate(
                              `/waterpurifier/productdetails/${product._id}`
                            )
                          }
                        >
                          <div>
                            <div>
                              <div>
                                <img
                                  src={`${Api_Url}${product?.ProductImage?.[0]}`}
                                  alt={product?.ProductName}
                                  className="img-fluid"
                                  style={{ width: "30px", height: "30px" }}
                                />
                              </div>
                              <div>{product.ProductName}</div>
                            </div>

                            <div className="text-success">
                              {`₹ ${parseInt(
                                product?.totalPriceWithTax
                              ).toLocaleString()}`}{" "}
                              /-
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="dropdown-item">No products found</div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="row d-flex justify-content-end ">
              <div className="col-md-12 p-0 d-flex justify-content-between align-items-center">
                <div className="flex-fill mx-1 text-center">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src={logoWhite}
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "fill",
                        }}
                      />
                    </Link>
                  </div>
                </div>
                <div
                  className="flex-fill mx-1 text-center "
                  style={{ width: "45%" }}
                >
                  <div
                    className="search_container w-100"
                    style={{ position: "relative" }}
                  >
                    <input
                      placeholder={displayText}
                      value={query}
                      onChange={handleInputChange}
                      onFocus={() => setShowDropdown(true)}
                      type="search"
                      className="search_field w-100"
                    />

                    <div className="search_div">
                      <MdSearch />
                    </div>

                    {showDropdown && (
                      <div className="search_container_dropdown">
                        {searchResults.length > 0 ? (
                          searchResults.map((product, index) => (
                            <div
                              key={index}
                              className="dropdown_item"
                              onClick={(event) => {
                                event.stopPropagation(); // Prevent closing the dropdown
                                navigate(
                                  `/${product?.CategoryName}/${product?.SubCategoryName}/productdetails/${product._id}`
                                );
                              }}
                            >
                              <div className="py-2 d-flex align-items-center">
                                <div>
                                  <div>
                                    <img
                                      src={`${Api_Url}${product?.ProductImage?.[0]}`}
                                      alt={product?.ProductName}
                                      className="img-fluid"
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        border: "1px solid #ccc",
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="px-2">
                                  <div style={{ fontWeight: "600" }}>
                                    {product.ProductName}
                                  </div>
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      color: "green",
                                    }}
                                  >
                                    {`₹ ${parseInt(
                                      product?.totalPriceWithTax
                                    ).toLocaleString()}`}{" "}
                                    /-
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="dropdown-item">No products found</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="menu-item-wrapper">
                      <div className="menu-item">
                        Our Categories
                        <FaChevronDown className="dropdown__arrow" />
                      </div>
                      {/* <div className="dropdown-content">
                        <div className="dropdown-inner">
                          <div className="d-flex">
                            {menuData?.products?.map((menu) => {
                              return (
                                <div className="me-5">
                                  <h5>{menu.title}</h5>

                                  <ul>
                                    {menu?.items?.map((item) => {
                                      return (
                                        <a href={item?.path}>
                                          <li className="ps-1">{item.name}</li>
                                        </a>
                                      );
                                    })}
                                  </ul>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div> */}

                      <div className="dropdown-content">
                        <div className="dropdown-inner">
                          <div className="d-flex">
                            {HeadersMenuData.map((menu, index) => (
                              <div key={index} className="me-3">
                                <div className="dropdown_items_heading">
                                  {menu.title}
                                </div>
                                <ul>
                                  {menu.items.map((item, subIndex) => (
                                    <a href={item.path} key={subIndex}>
                                      <li className="ps-1">{item.name}</li>
                                    </a>
                                  ))}
                                </ul>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="menu-item-wrapper">
                      <div className="menu-item">
                        Our Shop
                        <FaChevronDown className="dropdown__arrow" />
                      </div>

                      <ul className="shop__menu_dropdown dropdown__menu">
                        {menuData?.shops[0]?.items?.map((item, idx) => (
                          <li key={idx}>
                            <Link to={item.path} className="dropdown__link">
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div>
                      {userData?.mobile ? (
                        <div>
                          <div className="menu-item-wrapper">
                            <div className="menu-item">
                              Account
                              <FaChevronDown className="dropdown__arrow" />
                            </div>

                            <div className="shop__menu_dropdown dropdown__menu">
                              <a href="/userprofile">
                                <div className="dropdown__link d-flex align-items-center">
                                  <div>
                                    {userData?.ProfilePhoto ? (
                                      <div className="userProfilePhoto">
                                        <img
                                          src={`${Api_Url}${userData?.ProfilePhoto}`}
                                          alt=""
                                          className="userProfilePhoto"
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        <FaRegUserCircle />
                                      </div>
                                    )}
                                  </div>
                                  <div className="ps-2">
                                    {userData?.name && userData?.mobile
                                      ? userData?.name.slice(0, 7) + "..."
                                      : userData?.mobile}
                                  </div>
                                </div>
                              </a>

                              <a href="/editprofile">
                                <div className="dropdown__link">
                                  <MdOutlineEdit />
                                  <span className="ps-1">Edit Profile</span>
                                </div>
                              </a>

                              <a href="/orders">
                                <div className="dropdown__link">
                                  <MdOutlineCardGiftcard />
                                  <span className="ps-1">My Orders</span>
                                </div>
                              </a>

                              <a href="/cart">
                                <div className="dropdown__link">
                                  <MdOutlineShoppingCart />
                                  <span className="ps-1">Cart</span>
                                  <span>({userData?.items?.length})</span>
                                </div>
                              </a>

                              <a href="/editaddress">
                                <div className="dropdown__link">
                                  <MdOutlineLocationOn />
                                  <span className="ps-1">Addresses</span>
                                </div>
                              </a>

                              <a href="/gstnumber">
                                <div className="dropdown__link">
                                  <MdOutlineLocationOn />
                                  <span className="ps-1">GST No.</span>
                                </div>
                              </a>

                              <div
                                className="dropdown__link"
                                onClick={handleLogout}
                              >
                                <MdLogout />
                                <span className="ps-1">Logout</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Link to="/login">
                          <div className="menu-item">Login</div>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Sidenavbar />
        </div>
      )}

      {/* Header 2 */}
      {isScrolled && isDesktop && (
        <div className="header-container-white">
          <div className="phone_view_icons_div mobile-view">
            <div className="d-flex justify-content-between">
              <img
                src={logoWhite}
                className="img-fluid"
                alt=""
                style={{ width: "200px" }}
              />

              <Link
                to="#sidenavbar"
                className="offcanvas_btn text-white"
                data-bs-toggle="offcanvas"
                aria-controls="offcanvasScrolling"
              >
                <GiHamburgerMenu />
              </Link>
            </div>
          </div>

          <div className="container-fluid desktop-view">
            <div className="mobile-view">
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  className="w-100"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  placeholder="Search for products"
                  value={query}
                  onChange={handleInputChange}
                  onFocus={() => setShowDropdown(true)}
                />

                {/* Dropdown menu */}
                {showDropdown && (
                  <div className="search_container_dropdown">
                    {searchResults.length > 0 ? (
                      searchResults.map((product, index) => (
                        <div
                          key={index}
                          className="dropdown_item"
                          onClick={() =>
                            navigate(
                              `/waterpurifier/productdetails/${product._id}`
                            )
                          }
                        >
                          <div>
                            <div>
                              <div>
                                <img
                                  src={`${Api_Url}${product?.ProductImage?.[0]}`}
                                  alt={product?.ProductName}
                                  className="img-fluid"
                                  style={{ width: "30px", height: "30px" }}
                                />
                              </div>
                              <div>{product.ProductName}</div>
                            </div>

                            <div className="text-success">
                              {`₹ ${parseInt(
                                product?.totalPriceWithTax
                              ).toLocaleString()}`}{" "}
                              /-
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="dropdown-item">No products found</div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="row d-flex justify-content-end ">
              <div className="col-md-12 p-0 d-flex justify-content-between align-items-center">
                <div className="flex-fill mx-1 text-center">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src={logoColor}
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "fill",
                        }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="flex-fill mx-1" style={{ width: "45%" }}>
                  <div
                    className="search_container w-100"
                    style={{ position: "relative" }}
                  >
                    <input
                      placeholder={displayText}
                      value={query}
                      onChange={handleInputChange}
                      onFocus={() => setShowDropdown(true)}
                      type="search"
                      className="search_field w-100"
                    />

                    <div className="search_div">
                      <MdSearch />
                    </div>

                    {showDropdown && (
                      <div className="search_container_dropdown">
                        {searchResults.length > 0 ? (
                          searchResults.map((product, index) => (
                            <div
                              key={index}
                              className="dropdown_item"
                              onClick={() =>
                                navigate(
                                  `/${product?.CategoryName}/${product?.SubCategoryName}/productdetails/${product._id}`
                                )
                              }
                            >
                              <div className="py-2 d-flex align-items-center">
                                <div>
                                  <div>
                                    <img
                                      src={`${Api_Url}${product?.ProductImage?.[0]}`}
                                      alt={product?.ProductName}
                                      className="img-fluid"
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        border: "1px solid #ccc",
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="px-2">
                                  <div style={{ fontWeight: "600" }}>
                                    {product.ProductName}
                                  </div>
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      color: "green",
                                    }}
                                  >
                                    {`₹ ${parseInt(
                                      product?.totalPriceWithTax
                                    ).toLocaleString()}`}{" "}
                                    /-
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="dropdown-item">No products found</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="menu-item-wrapper">
                      <div className="menu-item">
                        Our Categories
                        <FaChevronDown className="dropdown__arrow" />
                      </div>
                      <div className="dropdown-content">
                        <div className="dropdown-inner">
                          <div className="d-flex">
                            {menuData?.products?.map((menu, index) => {
                              return (
                                <div className="me-5" key={index}>
                                  <h5>{menu.title}</h5>

                                  <ul>
                                    {menu?.items?.map((item) => {
                                      return (
                                        <a href={item?.path}>
                                          <li className="ps-1">{item.name}</li>
                                        </a>
                                      );
                                    })}
                                  </ul>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="menu-item-wrapper">
                      <div className="menu-item">
                        Our Shop
                        <FaChevronDown className="dropdown__arrow" />
                      </div>

                      <ul className="shop__menu_dropdown dropdown__menu">
                        {menuData?.shops[0]?.items?.map((item, idx) => (
                          <li key={idx}>
                            <Link to={item.path} className="dropdown__link">
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div>
                      {userData?.mobile ? (
                        <div>
                          <div className="menu-item-wrapper">
                            <div className="menu-item">
                              Account
                              <FaChevronDown className="dropdown__arrow" />
                            </div>

                            <div className="shop__menu_dropdown dropdown__menu">
                              <a href="/userprofile">
                                <div className="dropdown__link d-flex align-items-center">
                                  <div>
                                    {userData?.ProfilePhoto ? (
                                      <div className="userProfilePhoto">
                                        <img
                                          src={`${Api_Url}${userData?.ProfilePhoto}`}
                                          alt=""
                                          className="userProfilePhoto"
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        <FaRegUserCircle />
                                      </div>
                                    )}
                                  </div>
                                  <div className="ps-2">
                                    {userData?.name && userData?.mobile
                                      ? userData?.name.slice(0, 7) + "..."
                                      : userData?.mobile}
                                  </div>
                                </div>
                              </a>

                              <a href="/editprofile">
                                <div className="dropdown__link">
                                  <MdOutlineEdit />
                                  <span className="ps-1">Edit Profile</span>
                                </div>
                              </a>

                              <a href="/orders">
                                <div className="dropdown__link">
                                  <MdOutlineCardGiftcard />
                                  <span className="ps-1">My Orders</span>
                                </div>
                              </a>

                              <a href="/cart">
                                <div className="dropdown__link">
                                  <MdOutlineShoppingCart />
                                  <span className="ps-1">Cart</span>
                                  <span>({userData?.items?.length})</span>
                                </div>
                              </a>

                              <a href="/editaddress">
                                <div className="dropdown__link">
                                  <MdOutlineLocationOn />
                                  <span className="ps-1">Addresses</span>
                                </div>
                              </a>

                              <div
                                className="dropdown__link"
                                onClick={handleLogout}
                              >
                                <MdLogout />
                                <span className="ps-1">Logout</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Link to="/login">
                          <div className="menu-item">Login</div>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Sidenavbar />
        </div>
      )}
    </>
  );
};
