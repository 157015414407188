import { Api_Url } from "../constant/Url";

export const AddGSTNumber = async (data) => {
  try {
    const res = await fetch(`${Api_Url}/user/gst/addgstnumber`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // Ensure data is being passed correctly
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res?.status} ${res?.statusText}`);
    }
    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const DeleteGSTNumber = async (data) => {
  try {
    const res = await fetch(`${Api_Url}/user/gst/deletegstnumber`, {
      method: "Delete",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // Ensure data is being passed correctly
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res?.status} ${res?.statusText}`);
    }
    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const GetAllGSTNumber = async (data) => {
  try {
    const res = await fetch(`${Api_Url}/user/gst/getallgstnumber`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // Ensure data is being passed correctly
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res?.status} ${res?.statusText}`);
    }
    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};
