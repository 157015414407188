import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Toaster, toast } from "react-hot-toast";
import { getDecryptedCookie } from "../utils/Utils";
import { AddGSTNumber, DeleteGSTNumber, GetAllGSTNumber } from "../api/GSTApi";
import { MdDeleteSweep } from "react-icons/md";

export const GSTNumber = () => {
  const initialValues = {
    gstnumber: "",
  };

  const validationSchema = Yup.object().shape({
    gstnumber: Yup.string()
      .required("GST Number is required")
      .matches(
        /^[A-Z0-9]{15}$/,
        "GST number must be exactly 15 characters, with uppercase letters and digits only"
      ),
  });

  const [GSTData, SetGSTData] = useState(null);

  const fetchGSTNumbers = async () => {
    try {
      const userData = getDecryptedCookie("UserData");
      const mobile = userData?.mobile;

      if (!mobile) {
        toast.error("Mobile number not found!");
        return;
      }

      const response = await GetAllGSTNumber({ mobile });
      if (response.status === "success") {
        SetGSTData(response.data || []);
      } else {
        toast.error(response.message || "Failed to fetch GST numbers.");
      }
    } catch (error) {
      console.error("Error fetching GST numbers:", error);
      toast.error("An error occurred while fetching GST numbers.");
    }
  };

  useEffect(() => {
    fetchGSTNumbers();
  }, []);

  const handleFormSubmit = async (values, actions) => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
      const mobile = userData?.mobile; // Extract mobile number from userData
      console.log(values, "values");
      if (!mobile) {
        toast.error("Mobile number not found!");
        return;
      }

      const formData = {
        gstnumber: values.gstnumber.toUpperCase(),
        mobile: mobile,
      };

      const res = await AddGSTNumber(formData);
      console.log(res)

      if (res?.status === "success") {
        toast.success("GST Added successfully!", { duration: 3000 });
        actions.resetForm();
        fetchGSTNumbers();
      } else {
        toast.error(res?.message, { duration: 3000 });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }

    actions.setSubmitting(false); // Enable the submit button after process
  };

  const handleDeleteGSTNumber = async (data) => {
    const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
    const mobile = userData?.mobile; // Extract mobile number from userData
    if (!mobile) {
      toast.error("Mobile number not found!");
      return;
    }
    const formData = {
      gstnumber: data,
      mobile: mobile,
    };

    const res = await DeleteGSTNumber(formData);
    console.log(res);
    if (res?.status === "success") {
      toast.success("GST Delete successfully!", { duration: 3000 });
      fetchGSTNumbers();
    }
    try {
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <div className="row d-flex justify-content-center py-3">
        <div className="col-md-6">
          <Toaster />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ isSubmitting, values, handleChange }) => (
              <Form>
                <div className="pt-3 form_main_div">
                  <div className="row d-flex justify-content-between">
                    <div className="col-md-12 mt-1">
                      <div className="form_input_div">
                        <label>
                          GST Number <span style={{ color: "red" }}>*</span>
                        </label>
                        <Field
                          type="text"
                          name="gstnumber"
                          placeholder="Enter your GST Numbers"
                          className="Form_Field"
                          maxLength={15}
                        />
                        <ErrorMessage
                          name="gstnumber"
                          component="div"
                          className="text-danger mb-2"
                        />

                        <button
                          type="submit"
                          className="common_button bg-white"
                          disabled={isSubmitting}
                        >
                          Add GST Number
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <div className="row d-flex justify-content-center py-3">
        <div className="col-md-6 form_input_div">
          <div>
            <h4>All Saved GST Numbers</h4>
            {GSTData?.length > 0 ? (
              <ul className="list-group">
                {GSTData?.map((gst, index) => (
                  <li
                    key={index}
                    className="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <span style={{fontWeight:"600"}}>{gst}</span>
                    <button
                      className="common_button d-flex "
                      onClick={() => handleDeleteGSTNumber(gst)}
                    >
                      <span>Delete this GST Number</span>
                      <span>
                        <MdDeleteSweep className="text-danger fs-4 ms-2" />
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No GST numbers found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
