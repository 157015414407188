import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetSingleProductsApi } from "../api/ProductsApi";
import toast, { Toaster } from "react-hot-toast";
import { CreateReviewApi, FilterReviewApi } from "../api/ReviewApi";

export const WriteReview = () => {
  const { productId } = useParams();
  const [ProductReview, setProductReview] = useState(null);

  const getSingleProduct = async () => {
    try {
      const res = await GetSingleProductsApi(productId);
      if (res?.status === "success") {
        setProductReview(res?.data?.ProductRatingAndReviews);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleProduct();
  }, [productId]);

  // start review Write

  const [selectedStars, setSelectedStars] = useState(0);
  const [filteredReviews, setFilteredReviews] = useState(null);
  const [comment, setComment] = useState("");

  const handleStarClick = (index) => {
    setSelectedStars(index + 1); // Rating is 1-based
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset stars and comment
    setSelectedStars(0);
    setComment("");

    const reviewData = {
      userId: "6741a668be734f2c6e7dbe4d",
      reviewer: "Deepak", // Replace with dynamic reviewer name if available
      rating: selectedStars,
      comment: comment,
      productId: productId,
    };

    try {
      const response = await CreateReviewApi(reviewData);

      if (response?.status === "success") {
        toast.success(response?.message, { duration: 3000 });
        getSingleProduct();
      } else {
        toast.error(response?.message, { duration: 3000 });
      }
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  };

  const handleFilterReviews = async (star) => {
    const reviewData = {
      star: star,
      productId: productId,
    };

    try {
      const response = await FilterReviewApi(reviewData);

      if (response?.status === "success") {
        setFilteredReviews(response?.data); // Update state with filtered reviews
      } else {
        toast.error(response?.message, { duration: 3000 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ marginTop: "2rem" }}>
      <Toaster />

      <div className="row Section_Container_Box">
        {/* <h2>See what our Customers have to Say</h2> */}

        <div className="col-md-12 d-flex justify-content-center homepage_component_heading_txt">
          <h2>See what our Customers have to Say</h2>
        </div>

        <div className="col-md-6 homepage_component_heading_txt">
          <div className="row rating_container d-flex px-0">
            <div className="col-md-6 ">
              {ProductReview?.totalReviews > 0 ? (
                <div>
                  <h4 className="m-0">Ratings & Reviews</h4>
                  <div className="d-flex">
                    <div className="d-flex " style={{ fontSize: "30px" }}>
                      <div> {ProductReview?.averageRating?.toFixed(1)}</div>
                      <div className="pe-2 text-warning">★</div>
                    </div>
                  </div>
                  <p className="pb-0">{ProductReview?.totalReviews} Ratings</p>
                </div>
              ) : (
                <h4 className="m-0">Be the first to write a review</h4>
              )}
            </div>

            <div className="col-md-6">
              {Object.keys(ProductReview?.starCounts || {}).map((star) => {
                const count = ProductReview.starCounts[star]; // Number of reviews for this star
                const totalReviews = ProductReview?.totalReviews || 1; // Prevent division by zero
                const widthPercentage = (count / totalReviews) * 100; // Calculate percentage

                return (
                  <div
                    className="d-flex align-items-center filter_star"
                    key={star}
                    onClick={() => handleFilterReviews(star)}
                  >
                    <div className="d-flex justify-content-center fs-5">
                      <span>{star}</span>
                      <div className="text-warning">★</div>
                    </div>
                    <div
                      className="rating_star_progress_bar"
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        backgroundColor: "#f1f1f1",
                        height: "10px",
                      }}
                    >
                      <div
                        className={`progress_bar_star_${star}`}
                        style={{
                          width: `${widthPercentage}%`,
                          backgroundColor: "gold",
                          height: "100%",
                        }}
                      ></div>
                    </div>
                    <span style={{ marginLeft: "10px" }}>({count})</span>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="row rating_container d-flex review_form">
            <h4 className="mb-0">Write Review</h4>
            <div className="write_review ">
              {[...Array(5)].map((_, index) => (
                <span
                  key={index}
                  onClick={() => handleStarClick(index)}
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    color: index < selectedStars ? "gold" : "gray",
                  }}
                  className="fa fa-star"
                ></span>
              ))}
            </div>
            <form onSubmit={handleSubmit}>
              <textarea
                placeholder="Add a comment..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                rows="2"
                style={{ width: "100%", marginBottom: "10px" }}
                required
              ></textarea>
              <button type="submit" style={{ padding: "5px 10px" }}>
                Submit
              </button>
            </form>
          </div>
        </div>

        <div className="col-md-6">
          <div className="row px-3">
            <div
              className="rating_container"
              style={{ height: "425px", overflow: "auto", padding: "0px" }}
            >
              <div>
                {(filteredReviews?.length || ProductReview?.reviews?.length) >
                0 ? (
                  (filteredReviews || ProductReview?.reviews)?.map(
                    (ele, index) => (
                      <div key={index} className="user_rating_card">
                        <div className="d-flex align-items-center">
                          <div className="user_profile">
                            <span className="bx bx-user-circle"></span>
                          </div>
                          <span>{ele?.reviewer}</span>
                        </div>
                        <div className="d-flex align-items-center">
                          {/* Render stars dynamically */}
                          {[...Array(5)].map((_, i) => (
                            <i
                              key={i}
                              className={`fa fa-star ${
                                i < ele?.rating
                                  ? "text-warning"
                                  : "text-secondary"
                              }`}
                            ></i>
                          ))}
                          <span className="ps-2">
                            {new Date(ele?.reviewtime).toLocaleDateString(
                              "en-US",
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </span>
                        </div>
                        <div>{ele?.comment}</div>
                      </div>
                    )
                  )
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="pt-2">
                      No reviews have been given by customers for this product
                      yet
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
