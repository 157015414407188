import React from "react";
import Slider from "react-slick";
import img1 from "../../assets/images/shop-banners/1.png";
import img2 from "../../assets/images/shop-banners/2.png";
import img3 from "../../assets/images/shop-banners/3.png";
import img4 from "../../assets/images/shop-banners/4.png";
// import "../../styles/ShopByBrands.css";

export const ShopBanners = () => {
  const BannerSettings = {
    dots: false,
    infinite: true,
    speed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div>
      <div className="container-fluid desktop-view">
        <div className="row" style={{ marginBottom: "2rem" }}>
          <Slider {...BannerSettings} className="px-0">
            <div className="col-md-12 ">
              <a href="/waterpurifier">
                <img
                  src={img1}
                  alt=""
                  className="w-100"
                  style={{ height: "140px" }}
                ></img>
              </a>
            </div>
            <div className="col-md-12">
              <a href="/waterpurifier">
                <img
                  src={img2}
                  alt=""
                  className="w-100"
                  style={{ height: "140px" }}
                ></img>
              </a>
            </div>
            <div className="col-md-12">
              <a href="/waterpurifier">
                <img
                  src={img3}
                  alt=""
                  className="w-100"
                  style={{ height: "140px" }}
                ></img>
              </a>
            </div>
            <a href="/waterpurifier">
              <img
                src={img4}
                alt=""
                className="w-100"
                style={{ height: "140px" }}
              ></img>
            </a>
          </Slider>
        </div>
      </div>

      <div className="container-fluid mobile-view">
        <div className="row px-2">
          <Slider {...BannerSettings}>
            <div className="col-md-12 ">
              <a href="/waterpurifier">
                <img
                  src={img1}
                  alt=""
                  className="w-100"
                  style={{ height: "85px" }}
                ></img>
              </a>
            </div>
            <div className="col-md-12">
              <a href="/waterpurifier">
                <img
                  src={img2}
                  alt=""
                  className="w-100"
                  style={{ height: "85px" }}
                ></img>
              </a>
            </div>
            <div className="col-md-12">
              <a href="/waterpurifier">
                <img
                  src={img3}
                  alt=""
                  className="w-100"
                  style={{ height: "85px" }}
                ></img>
              </a>
            </div>
            <a href="/waterpurifier">
              <img
                src={img4}
                alt=""
                className="w-100"
                style={{ height: "85px" }}
                ></img>
            </a>
          </Slider>
        </div>
      </div>
    </div>
  );
};
