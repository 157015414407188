import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { getAllDeals } from "../api/DealsApi";
import { GetFilteredProducts } from "../api/ProductsApi";
import { Loader } from "../components/layout/Loader";
import { Api_Url } from "../constant/Url";
import { MdOutlineShoppingCart } from "react-icons/md";

dayjs.extend(duration);

export const Deals = () => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [timerColor, setTimerColor] = useState("black");
  const [currentDeal, setCurrentDeal] = useState(null);

  const calculateTimeLeft = (endTime) => {
    const now = dayjs();
    const end = dayjs(endTime);
    const diff = end.diff(now);

    if (diff <= 0) {
      return null; // Deal expired
    }

    const durationObj = dayjs.duration(diff);
    return {
      hours: durationObj.hours(),
      minutes: durationObj.minutes(),
      seconds: durationObj.seconds(),
    };
  };

  useEffect(() => {
    const fetchAllDeals = async () => {
      try {
        const res = await getAllDeals();
        if (res?.status === "success" && res?.data?.length > 0) {
          setCurrentDeal(res.data[0]); // Set the first deal
        }
      } catch (error) {
        console.error("Error fetching deals:", error);
      }
    };

    fetchAllDeals();
  }, []);

  useEffect(() => {
    if (currentDeal?.endTime) {
      const interval = setInterval(() => {
        const timeRemaining = calculateTimeLeft(currentDeal.endTime);

        if (!timeRemaining) {
          clearInterval(interval); // Stop the timer when the deal expires
          setTimeLeft(null);
        } else {
          setTimeLeft(timeRemaining);

          // Change timer color if less than 60 minutes are left
          if (dayjs(currentDeal.endTime).diff(dayjs(), "minute") <= 60) {
            setTimerColor("red");
          } else {
            setTimerColor("black");
          }
        }
      }, 1000);

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [currentDeal]);

  const [AllProductsData, setAllProductsData] = useState();

  const fetchFilteredProducts = async () => {
    const filterData = {
      CategoryName: "Water Purifiers",
      SubCategoryName: "",
      priceMin: "",
      priceMax: "",
      shopName: "",
      order: "",
      dealsType: "Black Thrusday Sale",
    };

    try {
      const res = await GetFilteredProducts(filterData);
      console.log(res, "AllProductsData");
      if (res?.status === "success") {
        setAllProductsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFilteredProducts();
  }, []);

  return (
    <div className="container Section_Container">
      <div className="row d-flex justify-content-center Section_Container_Box">
        <div className="col-md-12 d-flex justify-content-center homepage_component_heading_txt">
          <h2>{currentDeal?.dealName}</h2>
        </div>
      </div>
      <div>
        {currentDeal ? (
          timeLeft ? (
            <div style={{ color: timerColor }}>
              <div className="row ">
                <div className="col-12 p-0">
                  <div>
                    {AllProductsData ? (
                      <div className="row d-flex">
                        {AllProductsData.map((product) => (
                          <div className="col-md-3">
                            <div className="product-card" key={product._id}>
                              <div
                                className="card-content"
                                // onClick={() => handleViewProduct(product._id)}
                              >
                                <div className="image-wrapper">
                                  <img
                                    src={`${Api_Url}${product.ProductImage[0]}`}
                                    alt={product.ProductName}
                                  />
                                </div>
                                <div className="product-details">
                                  <h5>
                                    {product?.ProductName
                                      ? product?.ProductName.slice(0, 17) +
                                        "..."
                                      : product?.ProductName}
                                  </h5>
                                  <p>
                                    <b>
                                      ₹
                                      {parseInt(
                                        product.priceAfterDiscount
                                      ).toLocaleString()}
                                    </b>
                                    <span className="px-2">
                                      ₹
                                      {parseInt(
                                        product.totalPriceWithTax
                                      ).toLocaleString()}
                                    </span>
                                    <div className="discount">
                                      Save {product.discountPercentage}%
                                    </div>
                                  </p>

                                  <div
                                    className="brand-name"
                                    style={{ maxHeight: "40px" }}
                                  >
                                    Brand: {product.ShopName}
                                  </div>
                                  <div className="product-actions">
                                    <button className="view-details">
                                      View
                                    </button>
                                    <button
                                      className="add-to-cart"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // handleAddToCart(product);
                                      }}
                                    >
                                      <MdOutlineShoppingCart />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="loader">Loading...</div>
                    )}
                  </div>
                </div>
              </div>

              <h5 className="text-center pt-3">
                <span>DEAL WILL END IN</span>
                <span className="ps-2 text-success font-weight-bold">
                  {timeLeft.hours} hr {timeLeft.minutes} mins {timeLeft.seconds}{" "}
                  seconds
                </span>
              </h5>
            </div>
          ) : (
            <p>{currentDeal?.dealName}: Deal has expired</p>
          )
        ) : (
          <p>Loading deals...</p>
        )}
      </div>
    </div>
  );
};
