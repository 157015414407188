import React, { useEffect, useState } from "react";
import { FetchAllTestimonialsApi } from "../../api/TestimonialsApi";
import Slider from "react-slick";
import { Api_Url } from "../../constant/Url";
import Quote from "../../assets/images/icons/quote.svg";

export const Testimonials = () => {
  const [AllTestimonialsData, setAllTestimonialsData] = useState();

  const AllTestimonialsFetch = async () => {
    try {
      const res = await FetchAllTestimonialsApi();
      if (res?.status === "success") {
        setAllTestimonialsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    AllTestimonialsFetch();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3300,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200, // Large desktops
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // Tablets and small desktops
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile phones
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container Section_Container">
      <div className="row d-flex justify-content-center Section_Container_Box">
        <div className="col-md-12 d-flex justify-content-center homepage_component_heading_txt">
          <h2>Testimonials</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          {AllTestimonialsData ? (
            <Slider {...settings}>
              {AllTestimonialsData.map((ele, index) => (
                <div className=" px-2" key={index} data-aos="fade-up">
                  <div className="testimonials_main_div">
                    <div
                      className="testmonials_txt_box"
                      style={{ minHeight: "120px" }}
                    >
                      {ele?.Description
                        ? ele?.Description.slice(0, 210) + "..."
                        : ele?.Description}
                    </div>

                    <div className="testmonials_img_box">
                      <img
                        src={`${Api_Url}${ele?.Image}`}
                        alt={ele?.Name}
                        className="img-fluid"
                      />
                      <div className="testimonials_rating_div">
                        <span className="text-warning">★</span>
                        <span className="text-warning">★</span>
                        <span className="text-warning">★</span>
                        <span className="text-warning">★</span>
                        <span className="text-secondary">★</span>
                      </div>
                      <h5>{ele?.Name}</h5>
                    </div>
                    <div className="overlay_quote_img_box">
                      <img src={Quote} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="loader"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
