import React from "react";
import { BlogData } from "../../Data/BlogsData";
import image1 from "../../assets/images/off1.jpg";
import Slider from "react-slick";

export const LatestBlogs = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768, // Mobile and tablet view
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container Section_Container">
      <div className="row d-flex justify-content-center Section_Container_Box">
        <div className="col-md-12 d-flex justify-content-center homepage_component_heading_txt">
          <h2>Latest Blogs</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          {BlogData ? (
            <Slider {...settings}>
              {BlogData.map((ele, index) => (
                <div>
                  <div key={ele?._id || index} className="px-2">
                    <div>
                      <div className="Section_Card_2">
                        <img src={image1} alt="" className="img-fluid" />
                        <div className="Section_Card_2_Text_Container ">
                          <div className="Section_Card_2_Heading">
                            {ele?.title
                              ? ele?.title.slice(0, 80) + "..."
                              : ele?.title}
                          </div>
                          <div className="Section_Card_2_Content">
                            {ele?.description
                              ? ele?.description.slice(0, 125) + "..."
                              : ele?.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div key={index} className="p-3">
                      <div className="shop_cards">
                        <img
                          src={image1}
                          alt={ele.image}
                          className="img-fluid w-100"
                          style={{ objectFit: "contain" }}
                        />
                        <div className="content" style={{ minHeight: "120px" }}>
                          <h5 className="py-1 m-0">{ele.title}</h5>
                          <p>{ele.description}</p>
                        </div>
                      </div>
                    </div> */}
                </div>
              ))}
            </Slider>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="loader"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
