const menuData = {
  products: [
    {
      title: "Water Purifiers",
      items: [
        { name: "RO Water Purifier", path: "/waterpurifiers" },
        { name: "Water Filter", path: "/waterpurifiers" },
        { name: "UV Water Purifier", path: "/waterpurifiers" },
        { name: "Gravity Water Purifier", path: "/waterpurifiers" },
        { name: "Commercial Water Purifier", path: "/waterpurifiers" },
      ],
    },
    {
      title: "Water Dispensers",
      items: [
        { name: "Hot & Normal", path: "/waterdispensers" },
        { name: "Hot, Normal & Cold", path: "/waterdispensers" },
        { name: "Hot, Normal, Cold with RO", path: "/waterdispensers" },
        {
          name: "Hot, Normal, Cold & Cooling Cabinet",
          path: "/waterdispensers",
        },
      ],
    },
    // Uncomment and add more categories as needed
    // {
    //   title: "Kitchen Appliances",
    //   items: [
    //     { name: "Mixer Grinder", path: "/kitchenappliances" },
    //     { name: "Egg Boiler", path: "/kitchenappliances" },
    //   ],
    // },
    // {
    //   title: "Vacuum Cleaners",
    //   items: [
    //     { name: "Wet & Dry Vacuum Cleaner", path: "/vacuumcleaner" },
    //     { name: "Wiz Handheld Vacuum Cleaner", path: "/vacuumcleaner" },
    //   ],
    // },
  ],
  shops: [
    {
      title: "Our Shops",
      items: [
        { name: "Aquafresh", path: "https://aquafreshwaterpurifiers.in/" },
        { name: "Grand Plus", path: "https://grandpluswaterpurifier.com/" },
        {
          name: "Royal Aquafresh",
          path: "https://royalaquafreshwaterpurifier.com/",
        },
        { name: "Keel", path: "https://keelwaterpurifier.com/" },
      ],
    },
  ],
};

export default menuData;
