import React from "react";
import { Link } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa6";
import menuData from "../Data/MenuData";

export const SiteMap = () => {
  return (
    <div className="container">
      <h3 className="text-center mt-2">SITEMAP</h3>

      <div className="row pb-2" style={{ borderBottom: "2px solid #f1f1f1" }}>
        <h4 className="m-0">Our Products</h4>

        {/* {menuData?.map((ele, index) => {
          return (
            <div className="p-2 col-md-3 " key={index}>
              <div className="card p-2" style={{ minHeight: "160px" }}>
                <h5 className="m-0">{ele.title}</h5>
                <div>
                  {ele.items.map((item, idx) => (
                    <div key={idx}>
                      <Link to={item.path}>{item.name}</Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        })} */}
      </div>

      <div className="row py-2" style={{ borderBottom: "2px solid #f1f1f1" }}>
        <h4 className="m-0">Our Shops</h4>

        <div className="p-2 col-md-3">
          <div className="card p-2">
            <h5 className="m-0">Shops</h5>
            <div>
              {/* <Link to={item.path}>{item.name}</Link> */}

              <div>
                <a href="https://aquafreshwaterpurifiers.in/" target="blank">
                  <span>Aquafresh</span>
                </a>
              </div>

              <div>
                <a href="https://grandpluswaterpurifier.com/" target="blank">
                  <span>Grand Plus</span>
                </a>
              </div>

              <div>
                <a
                  href="https://royalaquafreshwaterpurifier.com/"
                  target="blank"
                >
                  <span>Royal Aquafresh</span>
                </a>
              </div>

              <div>
                <a href="https://keelwaterpurifier.com/" target="blank">
                  <span>Keel</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2" style={{ borderBottom: "2px solid #f1f1f1" }}>
        <h4 className="m-0">Other Useful Links</h4>

        <div className="p-2 col-md-3">
          <div className="card p-2" style={{ minHeight: "140px" }}>
            <h5 className="m-0">Company</h5>
            <div>
              <a href="/aboutus" target="blank">
                <span>About Us</span>
              </a>
            </div>
            <div>
              <a href="/contactus" target="blank">
                <span>Contact Us</span>
              </a>
            </div>
          </div>
        </div>

        <div className="p-2 col-md-3">
          <div className="card p-2">
            <h5 className="m-0">Policies</h5>
            <div>
              <a href="/disclaimer">
                <span>Disclaimer</span>
              </a>
            </div>

            <div>
              <a href="/termsconditions">
                <span>Terms & Conditions</span>
              </a>
            </div>
            <div>
              <a href="/returnrefundpolicy">
                <span>Return & Refund Policy</span>
              </a>
            </div>

            <div>
              <a href="/privacypolicy">
                <span>Privacy Policy</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
