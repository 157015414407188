import { Api_Url } from "../constant/Url";

export const getAllDeals = async () => {
  try {
    const res = await fetch(`${Api_Url}/deal/getalldeals`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};
