import React from "react";
import "../../styles/Footer.css";
import FooterAccordion from "./FooterAccordion";
import logo from "../../assets/images/logo/logo2.png";
import fb from "../../assets/images/social-media-icons/fb.png";
import insta from "../../assets/images/social-media-icons/insta.png";
import twitter from "../../assets/images/social-media-icons/twitter.png";
import yt from "../../assets/images/social-media-icons/yt.png";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="row py-2 d-flex justify-content-between">
          <div className="col-md-3">
            <a href="/">
              <img
                src={logo}
                className="my-2"
                alt=""
                style={{ backgroundColor: "white", width: "300px" }}
              />
            </a>
            <div>
              <div>
                <div>
                  <h5 className="m-0">Follow us:</h5>
                  <div className="d-flex">
                    <a
                      href="https://www.facebook.com/grandpluswaterpurifier"
                      target="#"
                    >
                      <img
                        src={fb}
                        alt=""
                        style={{ height: "42px", width: "40px" }}
                      ></img>
                    </a>

                    <a
                      href="https://www.instagram.com/grandpluswaterpurifier"
                      target="#"
                    >
                      <img
                        src={insta}
                        alt=""
                        style={{ height: "42px", width: "40px" }}
                      ></img>
                    </a>

                    <a
                      href="https://www.instagram.com/grandpluswaterpurifier"
                      target="#"
                    >
                      <img
                        src={twitter}
                        alt=""
                        style={{ height: "42px", width: "40px" }}
                      ></img>
                    </a>

                    <a
                      href="https://www.instagram.com/grandpluswaterpurifier"
                      target="#"
                    >
                      <img
                        src={yt}
                        alt=""
                        style={{ height: "42px", width: "40px" }}
                      ></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-8 desktop-view">
            <div className="row">
              <div className="col-md-5">
                <div className="footer_heading">
                  <a href="/waterpurifier">
                    <h5>Products</h5>
                  </a>
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      <a href="/waterpurifier">
                        <i className="bx bx-droplet" /> Water Purifier
                      </a>
                    </li>
                    <li>
                      <a href="/waterdispensers">
                        <i className="bx bx-droplet" /> Water Dispensers
                      </a>
                    </li>

                    <li>
                      <a href="/kitchenappliances">
                        <i className="bx bx-droplet" /> Kitchen Appliances
                        (Launching Soon)
                      </a>
                    </li>
                    <li>
                      <a href="/vacuumcleaner">
                        <i className="bx bx-droplet" /> Vacuum Cleaner
                        (Launching Soon)
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3">
                <div className="footer_heading">
                  <h5>Our Brands</h5>
                  <ul>
                    <li>
                      <a href="/https://aquafreshwaterpurifiers.in/">
                        <i className="bx bx-droplet" /> Aquafresh
                      </a>
                    </li>
                    <li>
                      <a href="/https://grandpluswaterpurifier.com/">
                        <i className="bx bx-droplet" /> Grand Plus
                      </a>
                    </li>
                    <li>
                      <a href="/https://royalaquafreshwaterpurifier.com/">
                        <i className="bx bx-droplet" /> Royal Aquafresh
                      </a>
                    </li>

                    <li>
                      <a href="/https://keelwaterpurifier.com/">
                        <i className="bx bx-droplet" /> Keel
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-4">
                <div className="footer_heading">
                  <h5>Useful Links</h5>
                  <ul>
                    <li>
                      <a href="/termsconditions">
                        <i className="bx bx-droplet" /> Terms & Conditions
                      </a>
                    </li>
                    <li>
                      <a href="/returnrefundpolicy">
                        <i className="bx bx-droplet" /> Return & Refund Policy
                      </a>
                    </li>
                    <li>
                      <a href="/privacypolicy">
                        <i className="bx bx-droplet" /> Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href="/disclaimer">
                        <i className="bx bx-droplet" /> Disclaimer
                      </a>
                    </li>
                    <li>
                      <a href="/sitemap">
                        <i className="bx bx-droplet" /> Sitemap
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mobile-view">
            <FooterAccordion />
          </div>
        </div>
      </div>

      <section className="copyright_section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="m-0 text-center">
                Copyright © 2024 Balaji Traders. All Right Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
