import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AddToCart,
  FetchAllProducts,
  GetFilteredProducts,
} from "../../api/ProductsApi";
import { getDecryptedCookie } from "../../utils/Utils";
import toast, { Toaster } from "react-hot-toast";
import { MdOutlineShoppingCart } from "react-icons/md";
import image1 from "../../assets/images/image/off1.jpg";
import { Api_Url } from "../../constant/Url";
import Slider from "react-slick";
import { Loader } from "../layout/Loader";

export const HomeWaterPurifiers = () => {
  const navigate = useNavigate();

  const userData = getDecryptedCookie("UserData");
  const token = userData?.token;
  const userId = userData?.userId;

  const [AllProductsData, setAllProductsData] = useState();

  const fetchFilteredProducts = async () => {
    const filterData = {
      CategoryName: "Water Purifiers",
      SubCategoryName: "",
      priceMin: "",
      priceMax: "",
      shopName: "",
      order: "",
    };

    try {
      const res = await GetFilteredProducts(filterData);
      if (res?.status === "success") {
        setAllProductsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFilteredProducts();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // Tablets and small desktops
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleAddToCart = async (productData) => {
    try {
      const data = {
        productId: productData._id,
        quantity: 1,
        userId: userId,
      };

      const res = await AddToCart(data, token);

      if (res?.status === "success") {
        toast.success(res?.message);
        window.location.reload();
      } else {
        toast.error("Error in Add to Product");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewProduct = (productId) => {
    navigate(`waterpurifier/productdetails/${productId}`);
  };

  return (
    <div>
      <Toaster />

      <div className="container Section_Container">
        <div className="row d-flex justify-content-center Section_Container_Box">
          <div className="col-md-12 d-flex justify-content-center homepage_component_heading_txt">
            <h2>Water Purifier</h2>
          </div>

          <div className="col-md-6" data-aos="zoom-in-up" data-aos-delay={200}>
            <img src={image1} alt="" className="img-fluid" />
          </div>

          <div className="col-md-6" data-aos="zoom-in-up" data-aos-delay={400}>
            <p className="m-0">
              Water is essential for life, and our purifiers are here to ensure
              that your drinking water is free from harmful contaminants. With
              multi-stage filtration, they efficiently remove toxins, bacteria,
              and unwanted chemicals, providing peace of mind and safety.
              Designed for durability, each purifier operates with minimal
              maintenance and delivers crisp, refreshing water at any time.
              Perfect for families, these purifiers are a valuable addition to
              modern homes, supporting a healthy, hydrated lifestyle. Upgrade
              your water quality with a purifier that makes every glass a moment
              of well-being. Perfect for families.Perfect for families, these
              purifiers are a valuable addition to homes.
            </p>
            <a href="/waterpurifiers">
              <button className="shop_now_btn">Shop Now</button>
            </a>
          </div>
        </div>
      </div>

      <div className="container Section_Container">
        <div className="row d-flex justify-content-center Section_Container_Box">
          <div className="col-md-12 d-flex justify-content-center homepage_component_heading_txt">
            <h2>Buy Water Purifier</h2>
          </div>

          <div className="col-12 desktop-view ">
            <div className="slider-container">
              {AllProductsData ? (
                <Slider {...settings}>
                  {AllProductsData.map((product) => (
                    <div className="product-card" key={product._id}>
                      <div
                        className="card-content"
                        onClick={() => handleViewProduct(product._id)}
                      >
                        <div className="image-wrapper">
                          <img
                            src={`${Api_Url}${product.ProductImage[0]}`}
                            alt={product.ProductName}
                          />
                        </div>
                        <div className="product-details">
                          <h5>{product.ProductName}</h5>
                          <p>
                            <b>
                              ₹
                              {parseInt(
                                product.priceAfterDiscount
                              ).toLocaleString()}
                            </b>
                            <span className="px-2">
                              ₹
                              {parseInt(
                                product.totalPriceWithTax
                              ).toLocaleString()}
                            </span>
                            <b className="discount">
                              Save {product.discountPercentage}%
                            </b>
                          </p>

                          <div className="brand-name">
                            Brand: {product.ShopName}
                          </div>
                          <div className="product-actions">
                            <button className="view-details">
                              View Product
                            </button>
                            <button
                              className="add-to-cart"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAddToCart(product);
                              }}
                            >
                              <MdOutlineShoppingCart />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div>
                  <Loader />
                </div>
              )}
            </div>
          </div>

          <div className="col-12 mobile-view ">
            <div className="slider-container">
              {AllProductsData ? (
                <Slider {...settings}>
                  {AllProductsData.map((product) => (
                    <div className="product-card" key={product._id}>
                      <div
                        className="card-content"
                        onClick={() => handleViewProduct(product._id)}
                      >
                        <div className="image-wrapper">
                          <img
                            src={`${Api_Url}/${product.ProductImage[0]}`}
                            alt={product.ProductName}
                          />
                        </div>
                        <div className="product-details">
                          <h5>
                            {product?.ProductName
                              ? product?.ProductName.slice(0, 17) + "..."
                              : product?.ProductName}
                          </h5>
                          <p>
                            <b>
                              ₹
                              {parseInt(
                                product.priceAfterDiscount
                              ).toLocaleString()}
                            </b>
                            <span className="px-2">
                              ₹
                              {parseInt(
                                product.totalPriceWithTax
                              ).toLocaleString()}
                            </span>
                            <div className="discount">
                              Save {product.discountPercentage}%
                            </div>
                          </p>

                          <div
                            className="brand-name"
                            style={{ maxHeight: "40px" }}
                          >
                            Brand: {product.ShopName}
                          </div>
                          <div className="product-actions">
                            <button className="view-details">View</button>
                            <button
                              className="add-to-cart"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAddToCart(product);
                              }}
                            >
                              <MdOutlineShoppingCart />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="loader">Loading...</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
