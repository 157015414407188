import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { AddToCart, FetchAllProducts } from "../../api/ProductsApi";
import { MdOutlineShoppingCart } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import Slider from "react-slick";
import { getDecryptedCookie } from "../utils/Utils";
import { Api_Url } from "../constant/Url";
import {
  FetchAllProducts,
  AddToCart,
  GetFilteredProducts,
} from "../api/ProductsApi";

export const SimilarProducts = () => {
  const navigate = useNavigate();

  const userData = getDecryptedCookie("UserData");
  const token = userData?.token;
  const userId = userData?.userId;

  const [AllProductsData, SetProductsData] = useState();

  const { categoryName, subCategoryName } = useParams();

  const fetchFilteredProducts = async () => {
    try {
      const decodedCategoryName = decodeURIComponent(categoryName || "");

      const filterData = {
        CategoryName: decodedCategoryName || "",
      };

      const res = await GetFilteredProducts(filterData);

      console.log(
        "GetFilteredProductsGetFilteredProductsGetFilteredProducts",
        res
      );

      if (res?.status === "success") {
        SetProductsData(res?.data);
      } else {
        console.error("Failed to fetch products:", res);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchFilteredProducts();
  }, [categoryName, subCategoryName]);

  useEffect(() => {
    fetchFilteredProducts();
  }, []);

  const handleAddToCart = async (productData) => {
    try {
      const data = {
        productId: productData._id,
        quantity: 1,
        userId: userId,
      };

      const res = await AddToCart(data, token);

      if (res?.status === "success") {
        toast.success(res?.message);
        window.location.reload();
      } else {
        toast.error("Error in Add to Product");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewProduct = (productId) => {
    navigate(`waterpurifier/productdetails/${productId}`);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3300,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // Tablets and small desktops
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container Section_Container">
      <div className="row d-flex justify-content-center Section_Container_Box">
        <div className="col-md-12 d-flex justify-content-center homepage_component_heading_txt">
          <h2>Similar Products</h2>
        </div>

        <Toaster />

        <div className="row ">
          <div className="col-12 desktop-view">
            <div className="slider-container">
              {AllProductsData ? (
                <Slider {...settings}>
                  {AllProductsData.map((product) => (
                    <div className="product-card" key={product._id}>
                      <div
                        className="card-content"
                        onClick={() => handleViewProduct(product._id)}
                      >
                        <div className="image-wrapper">
                          <img
                            src={`${Api_Url}${product.ProductImage[0]}`}
                            alt={product.ProductName}
                          />
                        </div>
                        <div className="product-details">
                          <h5>
                            {product?.ProductName?.length > 25
                              ? product?.ProductName.slice(0, 20) + "..."
                              : product?.ProductName}
                          </h5>
                          <p>
                            <b>
                              ₹
                              {parseInt(
                                product.priceAfterDiscount
                              ).toLocaleString()}
                            </b>
                            <span className="px-2">
                              ₹
                              {parseInt(
                                product.totalPriceWithTax
                              ).toLocaleString()}
                            </span>
                            <b className="discount">
                              Save {product.discountPercentage}%
                            </b>
                          </p>

                          <div className="brand-name">
                            Brand: {product.ShopName}
                          </div>
                          <div className="product-actions">
                            <button className="view-details">
                              View Product
                            </button>
                            <button
                              className="add-to-cart"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAddToCart(product);
                              }}
                            >
                              <MdOutlineShoppingCart />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="loader">Loading...</div>
              )}
            </div>
          </div>

          <div className="col-12 mobile-view p-0">
            <div className="slider-container">
              {AllProductsData ? (
                <Slider {...settings}>
                  {AllProductsData.map((product) => (
                    <div className="product-card" key={product._id}>
                      <div
                        className="card-content"
                        onClick={() => handleViewProduct(product._id)}
                      >
                        <div className="image-wrapper">
                          <img
                            src={`${Api_Url}${product.ProductImage[0]}`}
                            alt={product.ProductName}
                          />
                        </div>
                        <div className="product-details">
                          <h5>
                            {product?.ProductName
                              ? product?.ProductName.slice(0, 17) + "..."
                              : product?.ProductName}
                          </h5>
                          <p>
                            <b>
                              ₹
                              {parseInt(
                                product.priceAfterDiscount
                              ).toLocaleString()}
                            </b>
                            <span className="px-2">
                              ₹
                              {parseInt(
                                product.totalPriceWithTax
                              ).toLocaleString()}
                            </span>
                            <div className="discount">
                              Save {product.discountPercentage}%
                            </div>
                          </p>

                          <div
                            className="brand-name"
                            style={{ maxHeight: "40px" }}
                          >
                            Brand: {product.ShopName}
                          </div>
                          <div className="product-actions">
                            <button className="view-details">View</button>
                            <button
                              className="add-to-cart"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAddToCart(product);
                              }}
                            >
                              <MdOutlineShoppingCart />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="loader">Loading...</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
