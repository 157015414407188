import React, { useEffect, useState } from "react";
import { getDecryptedCookie } from "../utils/Utils";
import { GetAllOrdersApi } from "../api/UserApi";

export const Orders = () => {
  const [orderData, setOrderData] = useState(null);

  const handleOrdersApi = async () => {
    const userData = getDecryptedCookie("UserData");
    const token = userData?.token;
    const userId = userData?.userId;

    const data = {
      userId: userId,
    };

    try {
      const response = await GetAllOrdersApi(data);
      console.log(response);

      if (response?.status === "success") {
        setOrderData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleOrdersApi();
  }, []);

  return (
    <div>
      <h1>Orders</h1>
      {orderData?.length > 0 ? (
        <table border="1" style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Payment Method</th>
              <th>Total Price</th>
              <th>Is Paid</th>
              <th>Is Delivered</th>
              <th>Order Items</th>
            </tr>
          </thead>
          <tbody>
            {orderData.map((order) => (
              <tr key={order._id}>
                <td>{order._id}</td>
                <td>{order.paymentMethod}</td>
                <td>{order.totalPrice}</td>
                <td>{order.isPaid ? "Yes" : "No"}</td>
                <td>{order.isDelivered ? "Yes" : "No"}</td>
                <td>
                  <ul>
                    {order.orderItems.map((item) => (
                      <li key={item._id}>
                        Product ID: {item.product._id}, Quantity:{" "}
                        {item.quantity}
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No orders found.</p>
      )}
    </div>
  );
};
