import { Api_Url } from "../constant/Url";

export const FetchAllProducts = async () => {
  try {
    const res = await fetch(`${Api_Url}/product/getallproduct`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    });

    return await res.json();
  } catch (error) {
    console.log("An error occurred:", error);
    return {
      success: false,
      message: "An error occurred while fetching the products",
    };
  }
};

export const GetSingleProductsApi = async (productId) => {
  try {
    const res = await fetch(
      `${Api_Url}/product/getsingleProduct/${productId}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    return await res.json();
  } catch (error) {
    console.log("An error occurred:", error);
    return {
      success: false,
      message: "An error occurred while fetching the Products.",
    };
  }
};

export const AddToCart = async (data, token) => {
  try {
    const res = await fetch(`${Api_Url}/cart/createcart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token in the Authorization header
      },
      body: JSON.stringify(data), // Send the data as a JSON string in the request body
    });

    // Parse the response as JSON
    const responseData = await res.json();

    // Check if the request was successful
    if (!res.ok) {
      throw new Error(responseData.message || "Failed to add product to cart");
    }

    // Return the response data (e.g., the updated cart or success message)
    return responseData;
  } catch (error) {
    // Handle errors more explicitly
    console.error("An error occurred while adding to cart:", error.message);
    return { success: false, message: error.message }; // Return error message to handle in the calling function
  }
};

// export const GetFilteredProducts = async (data) => {
//   console.log("data before api call", data);
//   try {
//     const res = await fetch(
//       `http://localhost:9090/product/getAllFilteredProduct?CategoryName=${data?.CategoryName}&SubCategoryName=""&priceMin=${data?.priceMin}&priceMax=${data?.priceMax}&shopName=${data?.shopName}&sortBy=price&order=${data?.order}`,
//       // "http://localhost:9090/product/getAllFilteredProduct?CategoryName=Water Purifier&SubCategoryName=&priceMin=&priceMax=&sortBy=price&order=asc&shopName=",
//       {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     );
//     return await res.json();
//   } catch (error) {
//     console.error("Error fetching filtered products:", error);
//     throw error;
//   }
// };

export const GetFilteredProducts = async (data) => {
  try {
    // Dynamically construct query parameters
    const params = new URLSearchParams({
      CategoryName: data?.CategoryName || "",
      // SubCategoryName: data?.SubCategoryName || "",
      SubCategoryName:data?.SubCategoryName || "",
      priceMin: data?.priceMin || "",
      priceMax: data?.priceMax || "",
      shopName: data?.shopName || "",
      dealsType: data?.dealsType || "",
      sortBy: data?.sortBy || "price",
      order: data?.order || "asc",
    });

    // Construct the API URL
    let url = `${Api_Url}/product/getAllFilteredProduct?${params.toString()}`;

    // Replace '+' with spaces
    url = url.replace(/\+/g, " ");


    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    // Read the response body once
    const responseData = await res.json();


    return responseData; // Return the response data directly
  } catch (error) {
    console.error("Error fetching filtered products:", error);
    throw error;
  }
};
