import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { FetchAllOurBrands } from "../../api/OurBrandsApi";
import { Link } from "react-router-dom";
import { Api_Url } from "../../constant/Url";

export const OurBrands = () => {
  const [AllOurBrandData, SetAllOurBrandData] = useState();

  const AllOurBrands = async () => {
    try {
      const res = await FetchAllOurBrands();
      if (res?.status === "success") {
        SetAllOurBrandData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    AllOurBrands();
  }, []);

  const settings = {
    dots: false,
    infinite: true, // Enables infinite scrolling
    speed: 3000, // Controls how fast the slider moves
    slidesToShow: 4,
    slidesToScroll: 1, // Keep this small for a smooth transition effect
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0, // Forces the slider to move continuously
    cssEase: "linear", // Ensures constant speed
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1200, // Large desktops
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // Tablets and small desktops
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480, // Mobile phones
        settings: { slidesToShow: 2, slidesToScroll: 1 }, // Show 2 slides on mobile
      },
    ],
  };
  return (
    <div className="container Section_Container">
      <div className="row d-flex justify-content-center Section_Container_Box">
        <div className="col-md-12 d-flex justify-content-center homepage_component_heading_txt">
          <h2>Our Brands</h2>
        </div>
      </div>

      <div className="row">
        {AllOurBrandData ? (
          <Slider {...settings}>
            {AllOurBrandData.map((ele) => (
              <div className="px-2 " key={ele?._id} data-aos="fade-up">
                <Link target="_blank" to={ele?.OurBrandsUrl}>
                  <div className="Section_Card_1">
                    <img
                      src={`${Api_Url}${ele?.OurBrandsImage}`}
                      alt={ele?.OurBrandsName}
                      className="img-fluid"
                    />
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100px" }}
          >
            <div className="loader"></div>
          </div>
        )}
      </div>
    </div>
  );
};
