import React, { useEffect, useState } from "react";
import "../styles/CheckOut.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { GetSingleProductsApi } from "../api/ProductsApi";
import { getDecryptedCookie } from "../utils/Utils";
import { GetUserProfile } from "../api/UserApi";
import { Api_Url } from "../constant/Url";

export const CheckOut = () => {
  const [productdetails, setProductDetails] = useState(null); // Initialize as null to check loading state
  const [userAddress, setAddress] = useState(null);

  const { productId } = useParams();

  const getSingleProduct = async () => {
    try {
      const res = await GetSingleProductsApi(productId);
      console.log("GetSingleProductsApi", res);
      if (res?.status === "success") {
        setProductDetails(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleProduct();
  }, [productId]);

  const Productcheckout = async (price) => {
    const {
      data: { key },
    } = await axios.get(`${Api_Url}/paymentgateway/getkey`);

    const {
      data: { order },
    } = await axios.post(`${Api_Url}/paymentgateway/checkout`, { price });

    const options = {
      key, // Enter the Key ID generated from the Dashboard
      amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Shopping Cart",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      callback_url: `${Api_Url}/paymentgateway/paymentverify`,
      prefill: {
        name: "Deepak",
        email: "Deepal@example.com",
        contact: "9876543210",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  // Fetch User Profile

  const [userData, setUserData] = useState(null);

  const FetchUserProfile = async () => {
    try {
      const userData = getDecryptedCookie("UserData");
      const token = userData?.token;
      const mobile = userData?.mobile;

      if (!token) {
        console.log("Token not found in cookies");
        return;
      }

      const res = await GetUserProfile(mobile, token);

      if (res?.status === "success") {
        setUserData(res.data);
        setAddress(res?.data?.addresses?.[0]);
      } else {
        console.error("Failed to fetch user profile", res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchUserProfile();
  }, []);

  return (
    <>
      <section className="inner_page_tisection_bred">
        <div className="container-fluid">
          <div className="row align-items-center">
            {/* col */}
            <div className="col-lg-12">
              <div className="page_ti_inner_page">
                <ul>
                  <li className="active">
                    <Link to="/">Home</Link>
                  </li>
                  -
                  <li>
                    <Link to="/"> Checkout</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* col end */}
          </div>
        </div>
      </section>
      {/*Page Title Area End  */}

      {/* checkout main wrapper start */}
      <div className="checkout_section_page">
        <div className="container">
          <div className="row">
            {/* Checkout Billing Details */}
            <div className="col-lg-8">
              <div className="checkout_main_div">
                <h5 className="checkout-title">Billing Details</h5>
                <div className="billing_from_main_div">
                  <form method="POST">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="checkout_input_div">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Name"
                            name="name"
                            value={userData?.name}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="checkout_input_div">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter Email"
                            name="email"
                            value={userData?.email}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="checkout_input_div">
                          <label>Mobile No.</label>
                          <input
                            type="tel"
                            className="form-control"
                            placeholder="Enter Mobile"
                            name="mobile"
                            value={userData?.mobile}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="checkout_input_div">
                          <label>Pin Code</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Pin Code"
                            name="pincode"
                            value={userAddress?.pincode}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="checkout_input_div">
                          <label>Address (House/Street/Locality)</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Address"
                            name="address1"
                            value={userAddress?.address}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="checkout_input_div">
                          <label>State</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter State"
                            name="state"
                            value={userAddress?.state}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="checkout_input_div">
                          <label>Select Payment Method</label>
                          <select
                            name="payment"
                            className="form-control"
                            required
                          >
                            <option selected disabled>
                              Payment method
                            </option>
                            <option value="online">Pay Online</option>
                            <option value="cash">Cash On Delivery</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* Order Summary Details */}
            <div className="col-lg-4">
              <div className="order-summary-details">
                <h5 className="checkout-title">Price Details</h5>
                <div className="order-summary-content">
                  {/* Order Summary Table */}

                  <div className="order-summary-table">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td>Product Price</td>
                          <td>
                            ₹{" "}
                            {parseInt(
                              productdetails?.totalPriceWithTax
                            ).toLocaleString()}
                            /-
                          </td>
                        </tr>
                        <tr>
                          <td>Discount</td>
                          <td>{productdetails?.discountPercentage}%</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Total Amount (Inc. GST)</td>
                          <td>
                            ₹{" "}
                            {parseInt(
                              productdetails?.priceAfterDiscount
                            ).toLocaleString()}
                            /-
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  {/* Order Payment Method */}
                  <div className="order-payment-method">
                    <div className="summary-footer-area">
                      <div className="custom-control">
                        <input type="checkbox" required />
                        <label>
                          I have read and agree to the website
                          <a href="Terms-Conditions">terms and conditions.</a>
                        </label>
                      </div>
                      <button
                        type="submit"
                        name="placeorder"
                        className="btn btn-sqr"
                        onClick={() => Productcheckout(21998)}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
