import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import { FetchAllBanners } from "../../api/HomePageBannersApi";
import { Api_Url } from "../../constant/Url";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import "../../styles/HomePageBanners.css";

export const HomePageBanners = () => {
  const [DesktopBanners, SetDesktopBanners] = useState();
  const [MobileBanners, SetMobileBanners] = useState();
  const desktopSliderRef = useRef(null);
  const mobileSliderRef = useRef(null);

  const banners = async () => {
    try {
      const response = await FetchAllBanners();
      if (response?.status === "success") {
        SetDesktopBanners(response?.data[0]?.DesktopBanners);
        SetMobileBanners(response?.data[0]?.MobileBanners);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    banners();
  }, []);

  const goToNext = () => {
    if (window.innerWidth > 768) {
      desktopSliderRef.current?.slickNext();
    } else {
      mobileSliderRef.current?.slickNext();
    }
  };

  const goToPrev = () => {
    if (window.innerWidth > 768) {
      desktopSliderRef.current?.slickPrev();
    } else {
      mobileSliderRef.current?.slickPrev();
    }
  };

  const ArrowButton = ({ onClick, icon, className }) => (
    <button className={`custom-arrow ${className}`} onClick={onClick}>
      {icon}
    </button>
  );

  const sliderSettings = {
    dots: true, // Enable custom dots
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Disable default arrows
    autoplay: true,
    autoplaySpeed: 3000,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          zIndex: 3,
        }}
      >
        <ul style={{ margin: 0, padding: 0, display: "flex", gap: "8px" }}>
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i) => <div className="custom-dot-styling"></div>,
    responsive: [
      {
        breakpoint: 1200, // Large desktops
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // Tablets and small desktops
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile phones
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      {/* Desktop View */}
      <div className="container-fluid desktop-view">
        <div
          className="row"
          style={{ marginBottom: "2rem", position: "relative" }}
        >
          <div className="custom-arrows">
            <ArrowButton
              onClick={goToPrev}
              icon={<FaChevronLeft />}
              className="prev-arrow"
            />

            <ArrowButton
              onClick={goToNext}
              icon={<FaChevronRight />}
              className="next-arrow"
            />
          </div>

          {DesktopBanners?.length > 0 ? (
            <Slider ref={desktopSliderRef} {...sliderSettings} className="px-0">
              {DesktopBanners.map((image, index) => (
                <div key={index} className="img_box_pro_dt">
                  <img
                    src={`${Api_Url}${image}`}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="loader"></div>
            </div>
          )}
        </div>
      </div>

      {/* Mobile View */}
      <div className="container-fluid mobile-view">
         <div
          className="row"
          style={{ marginBottom: "2rem", position: "relative" }}
        >
          <div className="custom-arrows">
            <ArrowButton
              onClick={goToPrev}
              icon={<FaChevronLeft />}
              className="prev-arrow"
            />

            <ArrowButton
              onClick={goToNext}
              icon={<FaChevronRight />}
              className="next-arrow"
            />
          </div>
          
          {MobileBanners?.length > 0 ? (
            <Slider ref={mobileSliderRef} {...sliderSettings} className="px-0">
              {MobileBanners.map((image, index) => (
                <div key={index} className="img_box_pro_dt">
                  <img
                    src={`${Api_Url}${image}`}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="loader"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
