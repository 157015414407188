import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import { AddNewAddress, GetUserProfile, RemoveAddress } from "../api/UserApi";
import { getDecryptedCookie } from "../utils/Utils";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Toaster, toast } from "react-hot-toast";
import { indianStates } from "../Data/IndianStatesData";

export const EditAddress = () => {
  const [UserAddressData, setUserAddressData] = useState([]);

  const [userData, setUserData] = useState(null);

  const FetchUserProfile = async () => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
      const token = userData?.token;
      const mobile = userData?.mobile; // Use mobile instead of userId

      console.log(userData, "userData from cookie"); // Log userData to see if it contains mobile and token
      console.log(mobile, token, "mobile and token"); // Log both mobile and token

      if (!token) {
        console.log("Token not found in cookies");
        return;
      }

      // Call GetUserProfile API with mobile and token
      const res = await GetUserProfile(mobile, token);

      if (res?.status === "success") {
        setUserData(res.data);
        setUserAddressData(res?.data?.addresses);
      } else {
        console.error("Failed to fetch user user addresses", res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchUserProfile(); // Fetch the user profile when the component mounts
  }, []);

  const [showUpdateAddress, setShowUpdateAddress] = useState(false);
  const [showRemoveAddress, setShowRemoveAddress] = useState(false);
  const [showNewAddress, setShowNewAddress] = useState(false);

  const handleUpdateAddressClose = () => setShowUpdateAddress(false);
  const handleUpdateAddressShow = () => setShowUpdateAddress(true);

  const handleNewAddressClose = () => setShowNewAddress(false);
  const handleNewAddressShow = () => {
    setShowNewAddress(true);
  };

  const handleRemoveAddressClose = () => {
    setShowRemoveAddress(false);
    SetSelectedAddress("");
  };

  const [SelectedAddress, SetSelectedAddress] = useState(null);

  const handleRemoveAddressShow = async (data) => {
    setShowRemoveAddress(true);
    SetSelectedAddress(data);
    console.log(data);
    try {
      //   const userData = getDecryptedCookie("UserData");
      //   const token = userData?.token;
      //   const mobile = userData?.mobile;
      //   const addressId = data?._id
      //   console.log(userData,token,mobile,addressId)
      //   const res = await RemoveAddress(mobile, data?._id, token);
    } catch (error) {
      console.log(error);
    }
  };

  const initialValues = {
    address: "",
    state: "",
    pincode: "",
  };

  const validationSchema = Yup.object().shape({
    address: Yup.string().required("Address is required"),
    state: Yup.string().required("State is required"),
    pincode: Yup.string()
      .required("Please enter your pincode number")
      .matches(/^[0-5]{6}$/, "pincode number must be 6 digits"),
  });

  const handleFormSubmit = async (values, actions) => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
      const mobile = userData?.mobile; // Extract mobile number from userData
      console.log(values, "values");
      if (!mobile) {
        toast.error("Mobile number not found!");
        return;
      }

      const formData = {
        address: values.address,
        pincode: values.pincode,
        state: values.state,
      };

      // Call the AddNewAddress API with mobile and formData
      const res = await AddNewAddress(formData, mobile);

      console.log(res, "AddNewAddress");
      if (res?.status === "success") {
        toast.success("Address added successfully!", { duration: 3000 });
        actions.resetForm(); // Reset form fields on success
        handleNewAddressClose(); // Close the modal after removal
        FetchUserProfile(); // Refresh the address list after removal
      } else {
        toast.error(res.message || "Failed to add address.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }

    actions.setSubmitting(false); // Enable the submit button after process
  };

  const handleConfirmRemoveAddress = async (address) => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the user data from cookies
      const mobile = userData?.mobile; // Get mobile number
      const token = userData?.token; // Get authorization token

      if (!mobile || !address?._id || !token) {
        toast.error("Required data missing");
        return;
      }

      const res = await RemoveAddress(mobile, address._id, token); // Call the API to remove address

      if (res?.status === "success") {
        toast.success("Address removed successfully!");
        handleRemoveAddressClose(); // Close the modal after removal
        FetchUserProfile(); // Refresh the address list after removal
      } else {
        toast.error(res.message || "Failed to remove address.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="container-fluid">
      <Toaster position="top-right" reverseOrder={false} />

      <div className="py-3 d-flex justify-content-between">
        <h3>Your Addresses</h3>
        <button
          onClick={handleNewAddressShow}
          className="common_button bg-white"
        >
          Add new Address
        </button>
      </div>
      <div className="row">
        {Array.isArray(UserAddressData) && UserAddressData.length > 0 ? (
          UserAddressData.map((ele, index) => (
            <div className="col-md-2 mb-3" key={index}>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Address {index + 1}</h5>
                  <p className="card-text m-0">Address: {ele?.address}</p>
                  <p className="card-text m-0">Pincode: {ele?.pincode}</p>
                  <p className="card-text m-0">State: {ele?.state}</p>
                  <div>
                    {/* <span
                      onClick={() => {
                        handleUpdateAddressShow(ele);
                      }}
                      className="pe-2"
                      style={{ cursor: "pointer" }}
                    >
                      Edit
                    </span> */}
                    <button
                      onClick={() => {
                        handleRemoveAddressShow(ele);
                      }}
                      className="common_button mt-2"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h5 className="text-center">No Addresses Available</h5>
        )}
      </div>

      {/* Remove Address */}

      <div>
        <Modal
          show={showRemoveAddress}
          onHide={handleRemoveAddressClose}
          backdrop="static"
          keyboard={false}
          size="l"
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <p className="card-text m-0">
                  Address: {SelectedAddress?.address}
                </p>
                <p className="card-text m-0">
                  pincode: {SelectedAddress?.pincode}
                </p>
                <p className="card-text m-0">State: {SelectedAddress?.state}</p>
              </div>
              <h5>Are you sure you want to remove this address?</h5>
              <div className="d-flex">
                <button
                  onClick={() => handleConfirmRemoveAddress(SelectedAddress)}
                  className="common_button me-2"
                >
                  Yes
                </button>
                <button
                  onClick={handleRemoveAddressClose}
                  className="common_button"
                >
                  No
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {/* Update Address */}

      <div>
        <Modal
          show={showUpdateAddress}
          onHide={handleUpdateAddressClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Single Online Store Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="modaldata d-flex pb-3">
                <span>OurBrandsName : </span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {/* Add New Address */}

      <div>
        <Modal
          show={showNewAddress}
          onHide={handleNewAddressClose}
          backdrop="static"
          keyboard={false}
          size="l"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add a new Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
              >
                {({ isSubmitting, values, handleChange }) => (
                  <Form>
                    <div className="pt-3 form_main_div">
                      <div className="row d-flex justify-content-between">
                        <div className="col-md-12 mt-1">
                          <div className="form_input_div">
                            <label>
                              Address <span style={{ color: "red" }}>*</span>
                            </label>
                            <Field
                              type="text"
                              name="address"
                              placeholder="Enter your Address"
                              className="Form_Field"
                            />
                            <ErrorMessage
                              name="address"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="col-md-12 mt-1">
                          <div className="form_input_div">
                            <label>
                              State <span style={{ color: "red" }}>*</span>
                            </label>
                            <Field
                              as="select"
                              className="w-100 p-1"
                              style={{ border: "1px solid gray" }}
                              name="state"
                            >
                              <option value="">Select a State</option>
                              {indianStates?.map((state) => (
                                <option key={state} value={state}>
                                  {state}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="state"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="col-md-12 mt-1">
                          <div className="form_input_div">
                            <label>
                              Pincode <span style={{ color: "red" }}>*</span>
                            </label>
                            <Field
                              type="text"
                              name="pincode"
                              placeholder="Enter your pincode number"
                              maxLength={6}
                              className="Form_Field"
                            />
                            <ErrorMessage
                              name="pincode"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="common_button bg-white"
                      disabled={isSubmitting}
                    >
                      Create Address
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
