import { Api_Url } from "../constant/Url";

export const SearchProductApi = async (searchData) => {
  try {
    const res = await fetch(`${Api_Url}/product/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(searchData),
    });

    return await res.json();
  } catch (error) {
    console.log("An error occurred:", error);
    return {
      success: false,
      message: "An error occurred while fetching the Products.",
    };
  }
};
